import React from 'react'
import emailIcon from '../../assets/images/contact-page/email-icon.svg'
import phoneIcon from '../../assets/images/contact-page/phone-icon.svg'

const GetInTouchSection = () => {
  return (
    <section className='container mx-auto flex flex-col items-center md:flex-row justify-evenly mt-8 px-4 lg:w-90vw'>
      <div className='md:w-full'>
        <h2 className='m-0'>Get in touch.</h2>
        <p className='m-0 mt-8 text-center md:text-left p-0 w-80vw md:w-full'>
          Every business is different, and has its own challenges, goals and priorities. We want to get to know you and your business, and help you achieve your goals.
        </p>
      </div>
      <div className='flex flex-row self-end justify-evenly items-center mt-4 lg:mt-0 w-full'>
        <div className='flex flex-col items-start justify-center md:justify-end lg:justify-center mt-4'>
          <p className='m-0 mt-4 font-bold'>Telephone:</p>
          <p className='m-0 text-secondary'>0161 50 12345</p>
          <p className='m-0 mt-4 font-bold'>Email:</p>
          <span className='m-0 p-0 text-secondary'>&#115;&#097;&#108;&#101;&#115;&#064;&#119;&#101;&#100;&#111;&#099;&#111;&#100;&#101;&#046;&#099;&#111;&#046;&#117;&#107;</span>
        </div>
        <div className='flex flex-col xl:items-center justify-center mt-4'>
          <img src={phoneIcon} alt='phone icon' className='h-16 mt-4' />
          <img src={emailIcon} alt='email icon' className='h-12 mt-4' />
        </div>
      </div>
    </section>
  )
}

export default GetInTouchSection
