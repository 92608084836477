import React from 'react'

const MapSection = () => {
  return (
    <div className='container mt-8 h-80 lg:h-96'>
      <iframe
        width='100%'
        height='100%'
        id='gmap_canvas'
        src='https://maps.google.com/maps?q=151%20the%20rock&t=&z=13&ie=UTF8&iwloc=&output=embed'
        frameBorder='0'
        scrolling='no'
        marginHeight='0'
        marginWidth='0'
        title='google maps'
      ></iframe>
    </div>
  )
}

export default MapSection
