import React from 'react'
import { ImSpinner2 } from 'react-icons/im'
import mailbox from '../../assets/images/contact-page/mailbox.svg'
import useFormValidation from '../../custom-hooks/useFormValidation'
import WdcButton from '../elements/WdcButton'

const ContactFormSection = () => {
  const formInputs = {
    companydjiwhr: '',
    namedjiwhr: '',
    messagedjiwhr: '',
    emaildjiwhr: '',
    phonedjiwhr: '',
  }
  const requiredInputs = {
    emaildjiwhr: { validateOn: ['phonedjiwhr', 'emaildjiwhr'] },
    phonedjiwhr: { validateOn: ['phonedjiwhr', 'emaildjiwhr'] },
    namedjiwhr: { validateOn: ['namedjiwhr'] },
    messagedjiwhr: { validateOn: ['messagedjiwhr'] },
  }

  const { errors, handleChange, handleSubmit, inputs, isLoading, showSuccessMessage } = useFormValidation(formInputs, requiredInputs)

  return (
    <div className='container mt-8'>
      <div className='bg-yellow-100 bg-opacity-25 grid lg:grid-cols-4 pb-12 pt-8 px-4 text-center'>
        <div className={isLoading ? 'col-span-full flex flex-col h-96 items-center justify-center  px-4 row-start-2' : 'hidden'}>
          <p className='text-secondary-dark text-3xl'>Sending...</p>
          <ImSpinner2 className='animate-spin mt-4 text-3xl text-secondary-dark' />
        </div>
        <div
          className={showSuccessMessage ? 'col-span-full flex flex-col h-96 justify-center mx-auto px-4 row-start-2 sm:w-8/12 lg:w-1/2 ' : 'hidden'}
        >
          <p className='text-secondary-dark'>Your message has been sent. We will reply as soon as possible. Thanks for getting in touch!</p>
        </div>
        <img src={mailbox} alt='mailbox icon' className='lg:block lg:col-start-1 hidden lg:-mb-8 lg:ml-4 lg:row-start-2 lg:self-end lg:w-48' />
        <div className='border-b lg:col-start-2 lg:col-span-2 sm:mx-auto text-secondary-dark sm:w-80vw lg:w-full'>
          <h2 className='mt-0 md:text-center'>Request a callback</h2>
          <p className='mt-4'>
            Get in touch today to discuss your requirements and how you can leverage our years of experience to take your business to the next level.
          </p>
        </div>
        <form
          className={
            showSuccessMessage || isLoading
              ? 'hidden'
              : 'lg:col-start-2 lg:col-span-2 flex flex-col items-center mt-4 justify-evenly mx-auto row-start-2 sm:w-9/12 md:w-7/12 lg:w-full'
          }
          onSubmit={handleSubmit}
        >
          <div>
            <p className='mt-2 mb-0 pb-0 text-xs text-secondary-dark'>Please fill in all required fields: name, message and phone number or email.</p>
          </div>
          <div className='lg:flex lg:flex-row mt-4 mx-0 justify-between w-full'>
            <label htmlFor='namedjiwhr' className='sr-only'>
              name
            </label>
            <input
              className={`input-field lg:w-short-input ${errors.namedjiwhr && 'red-border'}`}
              type='text'
              placeholder='Name'
              name='namedjiwhr'
              value={inputs.namedjiwhr}
              onChange={handleChange}
              aria-required
              minLength='3'
              id='namedjiwhr'
            />
            <label htmlFor='companydjiwhr' className='sr-only'>
              company
            </label>
            <input
              className='input-field lg:w-short-input'
              type='text'
              placeholder='Company (optional)'
              name='companydjiwhr'
              value={inputs.companydjiwhr}
              onChange={handleChange}
              id='companydjiwhr'
            />
          </div>
          <div className='lg:flex lg:flex-row justify-between w-full'>
            <label htmlFor='phonedjiwhr' className='sr-only'>
              phone
            </label>
            <input
              className={`input-field lg:w-short-input ${errors.phonedjiwhr && errors.emaildjiwhr && 'red-border'}`}
              placeholder='Phone number'
              name='phonedjiwhr'
              value={inputs.phonedjiwhr}
              onChange={handleChange}
              aria-required={inputs.emaildjiwhr === ''}
              type='tel'
              id='phonedjiwhr'
              pattern='^[0-9 +\b]{10,15}$'
              title='Numbers and spaces only. Minimum length 10, maximum length 15.'
            />
            <label htmlFor='emaildjiwhr' className='sr-only'>
              email
            </label>
            <input
              className={`input-field lg:w-short-input ${errors.phonedjiwhr && errors.emaildjiwhr && 'red-border'}`}
              type='email'
              placeholder='Email'
              name='emaildjiwhr'
              value={inputs.emaildjiwhr}
              onChange={handleChange}
              aria-required={inputs.phonedjiwhr === ''}
              id='emaildjiwhr'
            />
          </div>
          <label htmlFor='messagedjiwhr' className='sr-only'>
            message
          </label>
          <textarea
            className={`input-field h-32 lg:h-56 ${errors.messagedjiwhr && 'border-red-500'}`}
            placeholder='Message'
            name='messagedjiwhr'
            value={inputs.messagedjiwhr}
            onChange={handleChange}
            aria-required
            id='messagedjiwhr'
          />
          <div className='absolute top-0 left-0 right-0 bottom-0 w-0 opacity-0 z-0'>
            <label htmlFor='name'>name</label>
            <input autoComplete='off' type='text' id='name' name='name' placeholder='Your name here' />
            <label htmlFor='email'>email</label>
            <input autoComplete='off' type='email' id='email' name='email' placeholder='Your email here'/>
            <label htmlFor='phone'>phone</label>
            <input autoComplete='off' type='phone' id='phone' name='phone' placeholder='Your phone here' />
            <label htmlFor='message'>message</label>
            <textarea autoComplete='off' placeholder='Message' name='message' id='message' />
          </div>
          <WdcButton buttonText='send' buttonType='submit' className='flex justify-center mt-8 w-28' />
        </form>
      </div>
    </div>
  )
}

export default ContactFormSection
